#firebaseui-auth-container {
    width: 100%;
    border: unset !important;
    box-shadow: unset !important;
}

.firebaseui-idp-button {
    max-width: unset;
    width: 100%;
    border-radius: 15px;
    padding: 15px !important;
}

.firebaseui-list-item {
    margin-bottom: 20px !important;
}

.firebaseui-idp-facebook {
    border: 3px solid #ffffff;
}

.firebaseui-idp-google {
    border: 3px solid #faadad;
}

.firebaseui-idp-password {
    border: 3px solid #faadad;
}

.firebaseui-idp-phone {
    border: 3px solid #ffffff;
}