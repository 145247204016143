html {
    overflow: auto;
    width: 100%;
}

body {
    height: 100%;
    position: fixed;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
    font-family: "Visby Round", serif !important;
    -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    margin-top: 16px;
    border-radius: 7px;
    background-color: #E0E8F7;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #BCBEC0;
    border-radius: 7px;
    height: 164px;
}

@font-face {
    font-family: 'Visby Round';
    src: local('Visby'), url(./../src/fonts/visby_round_cf_regular.otf) format('truetype');
}

.marker {
    background: transparent;
    border: unset;
    z-index: 100 !important;
}

.ad-marker {
    background: transparent;
    border: unset;
    z-index: 100 !important;
    border: 3px solid #ffbb2a;
    border-radius: 50%;
}

.sense-marker {
    background: transparent;
    border: unset;
    z-index: 90 !important;
}

.stop-icon {
    z-index: 160 !important;
}

.markerRealTime {
    background: transparent;
    border: unset;
    z-index: 400 !important;
}

.userReportMarker {
    background: transparent;
    border: unset;
}

.markerPreview {
    pointer-events: none;
    z-index: 200 !important;
}

.tooltip {
    width: 100px;
    background-color: #03a4bc;
    word-wrap: break-word;
    border: none;
    border-radius: 35px;
    color: white;
    font-size: 14px;
    position: absolute;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px 10px;
    padding-right: 15px;
    font-family: 'Visby Round', serif;
}

.tooltip span {
    font-family: 'Visby Round', serif;
    font-size: 16px;
    border: none;
}

.tipTooltip {
    font-family: "Visby Round", serif;
    border: none;
    border-radius: 8px;
}

.tipTooltipError {
    font-family: "Visby Round", serif;
    border: none;
    border-radius: 8px;
    color: #FF4B55;
}

.tipTooltip::before {
    display: none;
}

.tripContainer {
    padding-left: 466px;
    position: relative;
    outline: none;
    width: 100vw;
    height: 100vh;
}

.swiper-pagination > .swiper-pagination-bullet {
    opacity: 1;
    background-color: #959595;
    width: 8px;
    height: 8px;
}

.swiper-pagination > .swiper-pagination-bullet-active {
    background-color: white;
    width: 10px;
    height: 10px;
}

.choose-map {
    position: relative;
}


.mapboxgl-popup-content {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
}

.mapboxgl-ctrl-group {
    background-color: transparent;
    box-shadow: none !important;
}

.mapboxgl-ctrl-group button, .maplibregl-ctrl-group button {
    border-radius: 20px !important;
    width: 40px;
    padding: 8px;
    height: 40px;
    background-color: #fff !important;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%), 0 6px 12px 0 rgb(0 0 0 / 8%) !important;
}

.popup-arrival-time .maplibregl-popup-tip {
    display: none;
}

.popup-arrival-time .maplibregl-popup-content {
    background-color: #19aabf;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 16px;
    border-radius: 24px;
}
